.hero-container {
    /* background: linear-gradient(110deg, #3D5A80 60%, rgba(0, 0, 0, 0) 60%), linear-gradient(110deg, #131B23 61%, #F8C2B4 61%); */
    background: #02040F;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    box-shadow: black;
    object-fit: contain;
}

span {
    display: flex;
    color: #EE6C4D;
    font-size: 20px;
    margin-left: 25px;
    justify-content: center;
    align-items: center;
    text-align: left;

}

.func {
    display: block;
}

.curly {
    display: block;
}

.var {
    margin-left: 75px;
    color: #98C1D9;
    display: block;
}

.skills {
    color: #98C1D9;
    display: block;
}

.comment {
    color: #F2F3AE;
    display: block;
}

.todo{
    color: #C2F261;
    display: block;
}

.func-call {
    color: #EE6C4D;
}

.hero-container>h1 {
    color: #E0FBFC;
    font-size: 100px;
    padding: 35px;
    margin-top: -100px;
}

.hero-container>p {
    color: #E9F1F7;
}

.hero-container>h1:hover {
    color: #EE6C4D;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 5px;
}

@media screen and (max-width: 1440px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -75px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 40px;
        margin-top: -75px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}