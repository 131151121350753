.about-container{
    height: 100%;
    width: 100%;
    background-color: #02040F;
    color: #E0FBFC;
    font-size: 20px;
}

.list-container{
display: flex;
flex-direction: row;
justify-content: center;
padding-top: 50px;
}

.skills-list{
    display: flex;
    color: #F2F3AE;
    list-style: none;
}

.skills-item{
    padding: 25px;
}

@media screen and (max-width: 960px) {
    .skills-list{
        flex-direction: column;
    }

    
}

