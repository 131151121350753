.projects-container{
    background-color: #02040F;
    height: 100%;
    width: 100%;
    color: #EE6C4D;
}

.project-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10rem;
    padding-top: 5rem;
}

.internal-links{
    padding-top: 2rem;
    padding-bottom: 10rem;
}

img{
    height: 20rem;
    width: 35rem;
    box-shadow: 0 3px 10px #98C1D9;
}

.project-description{
    font-size: 20px;
    width: 50%;
    color: #E0FBFC;
    padding-left: 3rem;
    padding-right: 3rem;
}

@media screen and (max-width: 960px) {
.project-box{
    flex-direction: column;
}

    img{
        height: 15rem;
        width: 20rem; 
    }

    .project-description{
        padding-bottom: 2.5rem;
        width: 100%;
        font-size: 12px;
    }

    .hide{
        display: none;
    }

    
}

@media screen and (min-width: 961px) {

.project-img{
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
    
.no{
    display: none;
}



}